import { createGlobalStyle } from "styled-components";

const screenSizes = {
    smallScreen: "480px",
    mediumScreen: "820px",
}

const fonts = {
    themeFontFamily: 'Manrope',
    defaultFontFamily: 'Plus Jakarta Sans'
}

const fontSizes = {
    defaultFontSize: "1rem",
    mediumFontSize: "1.125rem",
    largeFontSize: "1.875rem",
    extraLargeFontSize: "3.813rem"
}

const lightThemeColors = {
    orange: "#F73E26",
    purple: "#6A31D2",
    darkBlue: "#231f7c",
    lightGray: "#FAF5F5" 
}

export const lightTheme = {
    ...fonts,
    ...lightThemeColors,
    ...fontSizes,
    ...screenSizes
}
  
  // TODO: Move this out into separate components 
export const GlobalStyle = createGlobalStyle`
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box !important;
    }
  
    body {
      margin: 0;
      padding: 0;
      font-family: ${props => props.theme.defaultFontFamily};
      font-size: ${props => props.theme.defaultFontSize};
      font-weight: 600;
      color: ${props => props.theme.darkBlue};
      background-color: ${props => props.theme.lightGray};
      -ms-overflow-style: none; /* for Internet Explorer, Edge */
      scrollbar-width: none; /* for Firefox */
      overflow-y: scroll; 
    }

    body::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }
`;