import React from "react";
import styled from "styled-components";

import { CenteredContainer } from "./styled-components/CenteredContainer";
import { MediumLogo } from "./Logo/MediumLogo";

const FooterContainer = styled.footer`
    border-top: 1px solid #ECE1F2;
    padding-top: 2rem;

    .copyright {
        text-align: center;
        margin: 5rem auto 1rem;
        opacity: .5;
    }
`

export const Footer = () => {
    return (
        <FooterContainer>
            <CenteredContainer>
                <MediumLogo />
                <div className="copyright">
                    © 2023 Plandid. App
                </div>
            </CenteredContainer>
        </FooterContainer>
    );
}

export default Footer;