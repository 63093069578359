import React from "react";
import styled from "styled-components";

import illustration from "../assets/BannerIllustration.png";

import { CenteredContainer } from "./styled-components/CenteredContainer";
import NewsletterButton from "./NewsletterButton";
import { LargeLogo } from "./Logo/LargeLogo";

// TODO: Reverse to mobile first
const BannerContainer = styled.div`
    padding-top: 10rem;
    display: flex;
    flex-direction: row;
    line-height: 1.8rem;

    @media (max-width: ${props => props.theme.smallScreen}) {
       flex-direction: column-reverse;
    }
`

const BannerIllustration = styled.div`
    width: 60%;
    height: 37.5rem;
    margin-left: -6.25rem;
    background-image: url(${props => props.img});
    background-size: contain;
    background-repeat: no-repeat;

    @media (max-width: ${props => props.theme.smallScreen}) {
        margin-left: 0;
        height: 20rem;
        width: 100%;
    }
`

const IntroContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 40%;
    padding: 8rem 1.75rem;

    .punch-line {
        margin: 2rem 0 0 1rem;

        span {
            font-weight: 400;
            font-size: .9rem;
        }
    }

    @media (max-width: ${props => props.theme.mediumScreen}) {
        padding: 3rem 1.75rem;
    }

    @media (max-width: ${props => props.theme.smallScreen}) {
        padding: 0 1.75rem 4rem;
        width: 100%;
    }
`

export const Banner = () => {
    return(
        <CenteredContainer>
            <BannerContainer>
                <BannerIllustration img={illustration} />
                <IntroContainer>
                    <LargeLogo />
                    <div className="punch-line">
                    Plandid is an AI-driven instant messaging web application for product managers in growth-phase tech startups. It provides users with trackable and easy-to-use file-sharing features to optimize their efficiency and productivity at work. <br /><br /><br />
                        <span>Sign up to be among the first ones to try out the beta version!</span>
                        <NewsletterButton buttonText="Sign Up"/>
                    </div>
                </IntroContainer>
            </BannerContainer>
        </CenteredContainer>
    );
}

export default Banner;