import React from "react";
import styled from "styled-components";

import { SmallLogo } from "./Logo/SmallLogo";
import { CenteredContainer } from "./styled-components/CenteredContainer";
import Button from "./Button";

const HeaderContainer = styled.div`
  width: 100%;
  background: ${props => props.theme.lightGray};
  position: fixed;
  z-index: 9999;
`

const CenteredHeaderContainer = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;

  @media (max-width: ${props => props.theme.smallScreen}) {
    padding-left: 1.875rem;
    flex-direction: row-reverse;
  }
`;

export const Header = () => {
    return (
      <HeaderContainer>
        <CenteredContainer>
          <CenteredHeaderContainer>
            <SmallLogo />
            <Button> Newsletter </Button>
          </CenteredHeaderContainer>
        </CenteredContainer>
      </HeaderContainer>
    );
}

export default Header;