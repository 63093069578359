import React from "react";
import styled from "styled-components";

import logo from "../../assets/logo.png";

import LogoContainer from "./LogoContainer";

const SizeContainer = styled.div`
    line-height: 3.75rem;
    font-size: 1.25rem;

    img {
        height: 1.75rem;
        width: auto;
    }

    @media (max-width: ${props => props.theme.smallScreen}) {
        display: none;
    }
`

export const SmallLogo = () => {
    return (
        <SizeContainer>
            <LogoContainer>
                <img src={logo} alt="Plandid App Logo" />
                Plandid.
            </LogoContainer>
        </SizeContainer>
    );
}

export default SmallLogo;