import React from "react";
import styled from "styled-components";

import { CenteredMiniContainer } from "./styled-components/CenteredContainer";
import FaqComponent from "./FaqComponent";
import NewsletterButton from "./NewsletterButton";

const MainContainer = styled.div`
    .summary {
        margin-top: 8rem;
        padding: 1.75rem;
        line-height: 1.8rem;
        width: 70%;
        font-size: ${props => props.theme.mediumFontSize};

        @media (max-width: ${props => props.theme.smallScreen}) {
            margin-top: 5rem;
            width: 100%;
        }
    }    
`

const FeaturesContainer = styled.div`
    margin: 8rem 1.75rem 1.75rem 20%;
    width: 80%;

    @media (max-width: ${props => props.theme.smallScreen}) {
        width: 100%;
        margin-left: 0;
        float: none;
    }

    .features {
        display: grid;
        gap: 5rem;
        grid-template-columns: 1fr 1fr;

        @media (max-width: ${props => props.theme.smallScreen}) {
            display: flex;
            flex-direction: column;
            padding: 1.75rem;
        }

        .feature {
            .emoji {
                font-size: 8rem;
            }

            .title {
                font-family: ${props => props.theme.themeFontFamily};
                font-size: ${props => props.theme.mediumFontSize};
                color: ${props => props.theme.orange};
                margin: 1.2rem 0;
            }

            .descrition {
                line-height: 1.8rem;
            }
        }
    }
`

const NewsletterContainer = styled.div`
    text-align: center;
    margin: 8rem auto;
    width: 70%;
    h1 {
        margin-bottom: 5rem;
        font-size: ${props => props.theme.largeFontSize};
    }

    .alternative {
        margin: 5rem auto;

        a {
            color: ${props => props.theme.orange};
        }
    }

    @media (max-width: ${props => props.theme.smallScreen}) {
        width: 100%;
    }
`

export const Main = () => {
    return(
        <MainContainer>
            <CenteredMiniContainer>
                <div className="summary">
                Plandid's user-friendly interface and trackable file-sharing features are optimized to enhance productivity and efficiency at work. 
                Say goodbye to endless email threads and lost files. With Plandid, you can streamline communication with your team, keep track of important files, and stay organized all in one place.
                Experience the power of AI chat and take your product management skills to the next level with Plandid. 
                </div>
                <FeaturesContainer>
                    <div className="features">
                        <div className="feature">
                            <div className="emoji">
                                💬
                            </div>
                            <div className="title">
                                AI-driven Instant Messaging
                            </div>
                            <div className="descrition">
                                With Plandid's AI-driven instant messaging feature, users can chat and brainstorm while AI analyzes and pins important information for them. 
                            </div>
                        </div>
                        <div className="feature">
                            <div className="emoji">
                                🔍
                            </div>
                            <div className="title">
                                Trackable File-Sharing
                            </div>
                            <div className="descrition">
                                Plandid offers easy-to-use and trackable file-sharing features to optimize productivity and efficiency at work.
                            </div>
                        </div>
                        <div className="feature">
                            <div className="emoji">
                                🔤
                            </div>
                            <div className="title">
                                Natural Language Retrieval
                            </div>
                            <div className="descrition">
                                Users can use natural language to describe their files shared in the chat to retrieve specific information.
                            </div>
                        </div>
                        <div className="feature">
                            <div className="emoji">
                                📍
                            </div>
                            <div className="title">
                                Improved Document Tracking
                            </div>
                            <div className="descrition">
                                Instead of having to remember the keywords or the date of the conversation, Plandid leverages AI to improve the document tracking experience.
                            </div>
                        </div>
                    </div>
                </FeaturesContainer>
                <FaqComponent />
                <NewsletterContainer>
                    <h1>
                        Want to Receive Updates About Plandid?
                    </h1>
                    <NewsletterButton buttonText="Sign Up for Our Newsletter"/>
                    <div className="alternative">
                        or just email us at <a href="mailto:founders@plandid.app">founders@plandid.app</a>
                    </div>
                </NewsletterContainer>
            </CenteredMiniContainer>
        </MainContainer>
    );
}

export default Main;