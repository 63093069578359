import styled from "styled-components";

export const CenteredContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`

export const CenteredMiniContainer = styled.div`
    max-width: 960px;
    margin: 0 auto;
`