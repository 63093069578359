import styled from "styled-components";

export const LogoContainer = styled.div`
    display: flex;
    flex-direction: row;
    font-family: ${props => props.theme.themeFontFamily};
    font-weight: 800;
    color: ${props => props.theme.orange};

    img {
        margin: 1rem 0.625rem;
    }
`;

export default LogoContainer;