import React from "react";
import styled from "styled-components";

const IndependentButtonContainer = styled.button`
  border-radius: 5px;
  padding: 5px 15px;
  background-color: ${props => props.theme.purple};
  border: none;
  font-size: ${props => props.theme.defaultFontSize};
  color: ${props => props.theme.lightGray};
  font-weight: 700;
  margin: 15px 10px;
  cursor: pointer;
  border: 1px solid ${props => props.theme.purple};
  transition-duration: .1s;

  &:hover {
    border: 1px solid ${props => props.theme.orange};
  }
`;

export const Button = ({ children }) => {
  return (
    <IndependentButtonContainer>
      { children }
    </IndependentButtonContainer>
  );
}

export default Button;