import React from "react";
import styled from "styled-components";

import logo from "../../assets/logo.png";

import LogoContainer from "./LogoContainer";

const SizeContainer = styled.div`
    line-height: 5rem;
    font-size: 2rem;

    img {
        height: 3rem;
        width: auto;
        margin-right: 1rem;
    }
`

export const LargeLogo = () => {
    return (
        <SizeContainer>
            <LogoContainer>
                <img src={logo} alt="Plandid App Logo" />
                Plandid.
            </LogoContainer>
        </SizeContainer>
    );
}

export default LargeLogo;