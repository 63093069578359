import React from "react";
import { ThemeProvider } from "styled-components";

import { lightTheme, GlobalStyle } from "./styles/global";

import Header from "./components/Header";
import Banner from "./components/Banner";
import Main from "./components/Main";
import Footer from "./components/Footer";

function App() {
  return (
    <ThemeProvider theme={lightTheme}>
      <GlobalStyle />
      <Header />
      <Banner />
      <Main />
      <Footer />
    </ThemeProvider>
  );
}

export default App;
