import React from "react";
import styled from "styled-components";

import Faq from "react-faq-component";
import { lightTheme } from "../styles/global";

const data = {
    title: "Frequently Asked Questions",
    rows: [
        {
          title: "What is Plandid?",
          content: "Plandid is an AI-driven instant messaging web application for product managers in growth-phase tech startups. It provides trackable and easy-to-use file-sharing features to optimize efficiency and productivity at work."
        },
        {
          title: "Who is Plandid for?",
          content: "Plandid is designed for tech-savvy founders or early employees of growth-phase startups whose daily tasks involve agile team collaboration."
        },
        {
          title: "How does Plandid improve productivity?",
          content: "Plandid leverages AI to improve the document tracking experience. Users can use natural language to describe files shared in chat for easy tracking and retrieval. Our platform provides trackable and easy-to-use file-sharing features, advanced document search, and easy file sharing, preview, and summary functions for agile team collaboration."
        },
        {
          title: "How is Plandid different from other messaging apps?",
          content: "Unlike other messaging apps, Plandid offers a noise-free workspace for product managers in growth-phase tech startups. Our platform provides trackable file sharing, preview, and summary functions, and advanced document search for specific information among the noise of millions of messages."
        },
        {
          title: "How is Plandid priced?",
          content: "Plandid plans to make money through a subscription-based tiered freemium model. The platform may offer premium features like audio advanced search or integrations with other tools for an additional cost."
        }
      ],
};

const styles = {
    bgColor: 'transparent',
    rowTitleColor: lightTheme.orange,
    width: "100%",
    rowContentColor: lightTheme.darkBlue,
    arrowColor: lightTheme.purple,
};

const config = {
    animate: true,
    openOnload: true,
};

const FaqContainer = styled.div`
    padding: 1.725rem;

    .faq-title {
        border: 0;
        margin: 8rem 0;

        h2 {
            text-align: center;
            width: 100%;
            color: ${props => props.theme.darkBlue};
            font-size: ${props => props.theme.largeFontSize};
        }
    }

    .faq-row-wrapper {

        .faq-row {
            border-bottom: 1px solid #ECE1F2;

            .row-title {
                font-family: ${props => props.theme.themeFontFamily};
                line-height: 1.5rem;
                margin-top: .625rem;
            }

            .row-content {
                margin-bottom: .625rem;
            }
        }
    }
`

export const FaqComponent = () => {
    return (
        <FaqContainer>
            <Faq
                data={data}
                styles={styles}
                config={config}
                />
        </FaqContainer>
    );
}

export default FaqComponent;