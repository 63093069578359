import React from "react";
import styled from "styled-components";


const NewsletterButtonContainer = styled.div`
    margin-top: 1.625rem;

    input {
        height: 2.5rem;
        line-height: 2.5rem;
        padding: 0 .5rem;
        width: 40%;
        border: 1px solid ${props => props.theme.purple};
        border-radius: 5px 0 0 5px;

        &:active, &:focus {
            border: 0;
        }
    }

    button {
        height: 2.5rem;
        line-height: 2.5rem;
        padding: 0 1rem;
        color: ${props => props.theme.lightGray};
        background: ${props => props.theme.purple};
        font-weight: 600;
        cursor: pointer;
        border: 0;
        border-radius: 0 5px 5px 0;
    }
`

export const NewsletterButton = ({ buttonText }) => {
    return(
        <NewsletterButtonContainer>
            {/* <span class="placeholder" >What is your email?</span> */}
            <input type="text" onkeydown="clean()" id="input" placeholder="Your email"/>
            <button class="submit">{buttonText}</button>
        </NewsletterButtonContainer>
    );
}

export default NewsletterButton;